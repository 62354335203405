import { getCurrentUserAuthorizationToken } from '../../graphql/apolloClient';
import { ReactSelectOptionType, UpdateKitDnaPrepLibPrepBody } from '../types';

// type definition for result of fetch kit information API
type FetchKitInfoAPIReturnType = {
  totalRecords: number;
  totalPages: number;
  data: Array<{
    analyzedDateTime: string;
    assignedDate: string;
    extractionDate: string;
    horseGUID: string;
    kitID: string;
    libPrepDateTime: string;
    recievedDate: string;
    reportDateTime: string;
    sampleSheetID: number;
    sampleValid: string;
    summaryComplete: boolean;
  }>;
};

// stores the API url to fetch searched kit ids
const searchKitIdsFetchUrl = (searchText: string) =>
  `${process.env.REACT_APP_KENTICO_API_BASE_URL as string}/Kit/SearchKitIDs?kitID=${searchText}`;

// stores the API url to update the kits by ids
export const updateKitsByIdsUrl = `${
  process.env.REACT_APP_KENTICO_API_BASE_URL as string
}/Kit/UpdateKitsByIds`;

// store the API url to fetch the kits information based on filters
const fetchKitInfoWithFiltersUrl = (params: string) =>
  `${process.env.REACT_APP_KENTICO_API_BASE_URL as string}/Kit/KitInfoWithFilters?${params}`;

// store the API url to update kits details in bulk
export const updateKitDnaPrepLibPrepDetailsURL = `${
  process.env.REACT_APP_KENTICO_API_BASE_URL as string
}/Kit/UpdateKitsInBulk`;

// function to fetch and return array of kit ids
export const searchKitIds = async (searchText: string): Promise<ReactSelectOptionType[]> => {
  /* const used to store select options array */
  const optionsArray: Array<ReactSelectOptionType> = [];

  // store fetch API response for the kitId fetch query
  const fetchAPIResponse = await fetch(searchKitIdsFetchUrl(searchText), {
    headers: {
      authorization: await getCurrentUserAuthorizationToken(),
    },
  });

  if (fetchAPIResponse.ok) {
    // store response data of fetch query
    const responseData = (await fetchAPIResponse.json()) as string[];

    // updating optionsArray according to the response data array
    responseData.forEach((item) => {
      optionsArray.push({
        label: item,
        value: item,
      });
    });

    return optionsArray;
  }

  throw new Error(await fetchAPIResponse.text());
};

// function that updates the receivedDateTime for the kits whose kitIDs are passed to update
export const updateReceivedDateTimeOfKitsByIds = async (bodyData: {
  // type definition for receivedDateTime
  receivedDateTime: string | null;
  // type definition for kit id
  kitID: string[];
}): Promise<void> => {
  // store update API response
  const updateAPIResponse = await fetch(updateKitsByIdsUrl, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      authorization: await getCurrentUserAuthorizationToken(),
    },
    body: JSON.stringify(bodyData),
  });

  if (!updateAPIResponse.ok) {
    throw new Error(await updateAPIResponse.text());
  }
};

// function to fetch kits information based on filters given as params
export const fetchKitInfoWithFilters = async (): Promise<FetchKitInfoAPIReturnType> => {
  // stores query parameters to pass
  const params = new URLSearchParams({
    pageSize: '10000',
    pageNumber: '1',
    recievedDate: 'not null',
    extractionDate: 'null',
    sortOrder: '1',
    sortField: 'receivedDateTime',
  });

  // store API response for the kit information with filters fetch query
  const fetchKitInfoAPIResponse = await fetch(fetchKitInfoWithFiltersUrl(params.toString()), {
    headers: {
      authorization: await getCurrentUserAuthorizationToken(),
    },
  });

  if (fetchKitInfoAPIResponse.ok) {
    // returns response data for fetch query
    return (await fetchKitInfoAPIResponse.json()) as FetchKitInfoAPIReturnType;
  }

  throw new Error(await fetchKitInfoAPIResponse.text());
};

// function that updates kit dna details like extractionDateTime, sampleValid, libPrepDateTime using kitID for multiple kits in bulk
export const updateKitDnaPrepLibPrepDetails = async (
  bodyData: UpdateKitDnaPrepLibPrepBody[],
): Promise<void> => {
  // store update API response
  const updateKitDnaPrepLibPrepDetailsAPIResponse = await fetch(updateKitDnaPrepLibPrepDetailsURL, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      authorization: await getCurrentUserAuthorizationToken(),
    },
    body: JSON.stringify(bodyData),
  });

  if (!updateKitDnaPrepLibPrepDetailsAPIResponse.ok) {
    throw new Error(await updateKitDnaPrepLibPrepDetailsAPIResponse.text());
  }
};

// function to fetch kits information based on filters given as params for sequencing
export const fetchKitInfoForSequencing = async (
  pageNumber: number,
): Promise<FetchKitInfoAPIReturnType> => {
  // stores query parameters to pass
  const params = new URLSearchParams({
    pageSize: '284',
    pageNumber: `${pageNumber}`,
    libPrepDate: 'not null',
    analyzedDate: 'null',
    sortOrder: '1',
    sortField: 'libPrepDateTime',
  });

  // store API response for the kit information with filters fetch query
  const fetchKitInfoForSequencingAPIResponse = await fetch(
    fetchKitInfoWithFiltersUrl(params.toString()),
    {
      headers: {
        authorization: await getCurrentUserAuthorizationToken(),
      },
    },
  );

  if (fetchKitInfoForSequencingAPIResponse.ok) {
    // returns response data for fetch query
    return (await fetchKitInfoForSequencingAPIResponse.json()) as FetchKitInfoAPIReturnType;
  }

  throw new Error(await fetchKitInfoForSequencingAPIResponse.text());
};
